import React from 'react';
import './stylesheets/sitewide.scss';
import HomePage from './pages/HomePageV2';

const App = () => (
		<div className="App" id="outerContainer">
		  <HomePage />
		</div>
)

export default App;



