import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from '@fortawesome/free-solid-svg-icons/faIdCard'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import './HomePage.scss';
import LazyLoad from 'react-lazyload';

const HomePage = () => (
		<div className="HomePage">

		  <div className="component-container" id="accordion">

			<div className="HeroPanel panel">
			  <h1 className="hero-title text-center">
				Karina Codes
			  </h1>

			  <div className="hero-links text-center">
				{/*<a href="https://github.com/karina-y" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub} title="Github" /></a>*/}
				<a href="https://linkedin.com/in/karina-y" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} title="Linkedin" /></a>
				<a href="Karina Y - Resume.pdf" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faIdCard} title="Resume" /></a>
			  </div>
			</div>

			<div className="AboutPanel panel">
			  <div className="left-panel panel-desc-container">
				<p className="subtitle text-left">
				  My name is Karina. I've been developing websites, mobile apps, desktop apps, and software for over 8 years now.
				  <br/><br/>
				  {/*I once wrote a <a href="https://github.com/karina-y/YASSS-KERNEL/blob/master/bootloader.asm" target="_blank" rel="noopener noreferrer">kernel</a> while getting my hair done, all it does is boot up and print "YASSSSSS BITCH", but still.... pretty cool, right?*/}
				  {/*<br/><br/>*/}
				  I currently work in fintech and take on freelance projects in my spare time.
				</p>
			  </div>

			  <div className="right-panel panel-title-container">
				<h2 className="panel-title text-right">
				  Who am I?
				</h2>
			  </div>
			</div>

			<LazyLoad height={'100%'}
					  once={true}>

			  <React.Fragment>
				<div className="SkillsPanel panel">
				  <div className="left-panel panel-title-container">
					<h2 className="panel-title text-left">
					  What's <br /> my stack?
					</h2>
				  </div>

				  <div className="right-panel panel-desc-container lg text-center">
					<img src="/images/ts.png" title="Typescript" alt="Typescript" className="zoom"/>
					<img src="/images/aws.png" title="AWS (serverless)" alt="AWS (serverless)" className="zoom"/>

					<br/>

					<img src="/images/reactjs.png" title="ReactJS and React Native" alt="ReactJS and React Native"
						 className="zoom"/>
					<img src="/images/node.png" title="Node" alt="Node" className="zoom"/>
					<img src="/images/js.png" title="Javascript" alt="Javascript" className="zoom"/>

					<br/>

					<img src="/images/sass.png" title="SCSS" alt="SCSS" className="zoom"/>
					<img src="/images/linux.png" title="Linux" alt="Linux" className="zoom"/>
					<img src="/images/meteorjs.png" title="MeteorJS" alt="MeteorJS" className="zoom" className="zoom"/>
					<img src="/images/mongodb.png" title="MongoDB" alt="MongoDB" className="zoom"/>

				  </div>

				  <div className="right-panel md text-center">
					<a href="https://github.com/karina-y/meteor-react-sample-code" target="_blank" rel="noopener noreferrer">
					  <img src="/images/reactjs.png" title="ReactJS and React Native" alt="ReactJS and React Native" className="zoom" />
					</a>

					<a href="https://github.com/karina-y/serverless-boilerplate" target="_blank" rel="noopener noreferrer">
					  <img src="/images/aws.png" title="AWS (serverless)" alt="AWS (serverless)" className="zoom" />
					</a>

					<img src="/images/js.png" title="Javascript" alt="Javascript"/>
					<img src="/images/es6.png" title="EcmaScript 6" alt="EcmaScript 6"/>
					<img src="/images/less.png" title="Less" alt="Less"/>
					<img src="/images/sass.png" title="SCSS" alt="SCSS"/>
					<img src="/images/linux.png" title="Linux" alt="Linux"/>

					<a href="https://github.com/karina-y/meteor-react-sample-code" target="_blank" rel="noopener noreferrer">
					  <img src="/images/meteorjs.png" title="MeteorJS" alt="MeteorJS" className="zoom" />
					</a>

					<img src="/images/mongodb.png" title="MongoDB" alt="MongoDB"/>
					<img src="/images/openbci.png" title="Open BCI Hardware" alt="Open BCI Hardware"/>
					<img src="/images/reactbootstrap.png" title="React Bootstrap" alt="React Bootstrap"/>
					<img src="/images/electron.png" title="Electron" alt="Electron"/>
					<img src="/images/visjs.png" title="VisJS" alt="VisJS"/>
					<img src="/images/sass.png" title="SCSS" alt="SCSS"/>
				  </div>
				</div>

				<div className="PortfolioPanel panel">
				  <div className="left-panel panel-desc-container">
					<div className="scrolling-image">
					  <img src="/images/40yoz.png" title="Website" alt="Website Sample"/>
					</div>

					<div className="scrolling-image">
					  <img src="/images/betagig.jpg" title="Website" alt="Website Sample" className="fast" />
					</div>

					<div className="scrolling-image">
					  <img src="/images/ios.jpg" title="Website" alt="Website Sample" className="med" />
					</div>

					<div className="non-scrolling-image">
					  <img src="/images/ldi.png" title="Website" alt="Website Sample" className="fast" />
					</div>

					<div className="scrolling-image">
					  <img src="/images/sona.png" title="Website" alt="Web Sample" className="fast" />
					</div>

					<div className="scrolling-image">
					  <img src="/images/sprout_01.png" title="Mobile App" alt="App Sample" />
					</div>

					{/*<div className="scrolling-image">
					  <img src="/images/sprout_02.png" title="Mobile App" alt="App Sample" className="fast" />
					</div>*/}

					{/*<div className="scrolling-image">
					  <img src="/images/sprout_03.png" title="Mobile App" alt="App Sample" className="med" />
					</div>*/}

					{/*<div className="scrolling-image">
					  <img src="/images/sprout_04.png" title="Mobile App" alt="App Sample" className="fast" />
					</div>*/}

				  </div>

				  <div className="right-panel panel-title-container">
					<h2 className="panel-title text-right">
					  I've got a <br /> portfolio too

					  <p className="subtitle">
						(more to come)
					  </p>
					</h2>
				  </div>
				</div>
			  </React.Fragment>

			</LazyLoad>

			<div className="Footer text-center">
			  &copy; Karina Y. {(new Date()).getFullYear()} | karinafullstack@gmail.com
			  <br/>
			  <br/>
			  <b>I do not collect any data.</b>
			</div>

		  </div>

		</div>
);


export default HomePage;
